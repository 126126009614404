






/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import {
  Variant,
  VariantsSwitchProps,
  VariantsSwitchType,
  variantsSwitchTypeRegistry
} from './VariantsSwitch.contracts'

@Component({ name: 'VariantsSwitch' })
export class VariantsSwitch extends Vue {
  @PropSync('model', { required: false, default: null })
  public _model!: VariantsSwitchProps['model']

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isFaF!: boolean

  @Prop({ type: Array, required: false })
  public variants?: Variant[]

  @Prop({ type: String, required: false, default: VariantsSwitchType.Default })
  public type?: string

  /**
   * Get component by type.
   */
  public get component (): VueConstructor {
    if (typeof this.type === 'undefined') {
      return variantsSwitchTypeRegistry[VariantsSwitchType.Default]
    }

    return variantsSwitchTypeRegistry[this.type]
  }
}

export default VariantsSwitch
